<template>
  <div>
    <div class="uk-margin-top uk-card uk-card-default uk-padding simp-card">
      <form
        class="uk-form-stacked"
        data-vv-scope="form-jenis-kandang"
        @submit.prevent="onUpload('form-jenis-kandang')"
      >
        <div class="uk-margin-bottom font-filter">
          Import Data Jenis Kandang
        </div>
        <div class="uk-margin-small-bottom uk-text-bold uk-text-large">
          1. Pilih file yang ingin diupload:
        </div>
        <div>
          <p>Pilih File: </p>
          <p v-if="file_import">
            {{ file_import.name }}
          </p>
        </div>
        <div class="uk-margin">
          <div uk-form-custom="target: true">
            <input 
              ref="file_import" 
              v-validate="'required'"
              type="file"
              name="file"
              accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
              :disabled="btn_upload"
              :class="{'uk-form-danger': errors.has('form-jenis-kandang.file')}"
              @change="handleFileUpload"
            >
            <input
              class="uk-input uk-form-width-large"
              type="text"
              placeholder="Pilih .xlsx file"
              disabled
            >
            <button class="uk-button uk-button-upload">
              Browse
            </button>
          </div>
        </div>
        <span
          v-show="errors.has('form-jenis-kandang.file')"
          class="uk-text-small uk-text-danger"
        >{{ errors.first('form-jenis-kandang.file') }}</span>
        <div class="uk-text-small uk-text-secondary">
          Berikut ini template file excel dan pastikan upload sesuai template.
          <a href="/files/master-data/Master Data Jenis Kandang.xlsx">Klik disini</a>
        </div>
        <div class="uk-margin-top uk-margin-small-bottom uk-text-bold uk-text-large">
          2. Upload File:
        </div>
        <div class="uk-margin-small-bottom uk-form-controls">
          <button
            class="uk-button uk-button-primary uk-width-xlarge uk-margin-small-button"
            :disabled="btn_upload"
          >
            <span
              v-show="btn_upload != true"
              uk-icon="icon: upload; ratio: 0.9"
              class="uk-margin-small-right"
            />Upload Data
          </button> 
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import {
  notificationDanger,
  notificationSuccess
} from '@/utils/notification'

export default {
  data() {
    return {
      file_import: null,
      btn_upload: false
    }
  },
  methods: {
    ...mapActions({
      importCageCategory: 'cageCategory/importCageCategory'
    }),
    async onUpload(scp) {
      this.btn_upload = true
      this.$validator.validateAll(scp).then((success) => {
        if (success) {
          if (this.$validator.errors.any()) return
          this.uploadAction(scp)
        } else {
          this.btn_upload = false
        }
      })
    },
    async uploadAction(scp) {
      const app = this
      const formData = new FormData()
      let successMsg = `berhasil di import`
      let failedMsg = `Something went wrong`
      let title
      let response
      if (scp === 'form-jenis-kandang') {
        formData.append('file', app.file_import)
        await app.$store.dispatch('cageCategory/importCageCategory', formData)
        const res      = await app.$store.getters['cageCategory/importData']
        response = res
        title = `Jenis Kandang`
      }
      if (response.message === 'OK') {
        notificationSuccess(`${title} ${successMsg}`)
        app.btn_upload = false
        setTimeout(function () {
          app.$router.push('/admin/jenis-kandang')
          app.$router.go(0)
        }, 1000)
        app.resetInput()
      } else {
        notificationDanger(`${title} ${failedMsg}`)
        app.btn_upload = false
      }
    },
    handleFileUpload(){
      this.file_import = this.$refs.file_import.files[0]
    },
    resetInput() {
      this.$refs.file_import.value = null
      this.handleFileUpload()
    }
  }
}
</script>

<style scoped>
.uk-button-upload{
  position: absolute;
  right: 0px;
  background: #F0F0F0;
  border: 1px solid #D4DFF5;
  border-radius: 0px 4px 4px 0px;
  box-shadow: none;
}
.uk-text-large{
  color: black;
}
p{
  color: #6D7278;
}
.uk-form-controls{
  width: 500px;
  color:#4DA0E7;
}
</style>
